import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"


const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <h1>About</h1>
      <p>
        Hi, I am Kyriakos, a Software and Data Engineer with passion for running and baking! :)
      </p>

      <p>See my work at:</p>
      <ul>
        <li>
          <OutboundLink href="https://github.com/kyrsideris" target="_blank" rel="noopener noreferrer">
            github.com/kyrsideris
          </OutboundLink>
        </li>
      </ul>

      <p>Connect with me in:</p>
      <ul>
        <li>
          <OutboundLink href="https://www.linkedin.com/in/kyriakossideris/" target="_blank" rel="noopener noreferrer">
            linkedin.com/in/kyriakossideris
          </OutboundLink> and
        </li>
        <li>
          <OutboundLink href="https://twitter.com/kyrsideris" target="_blank" rel="noopener noreferrer">
            twitter.com/kyrsideris
          </OutboundLink>
        </li>
      </ul>


      <h2 id="running">Running</h2>
      <p>You can see my latest running acrivities here:</p>
      <iframe title='strava-feed' height='454' width='300' frameBorder='0' allowtransparency='true' scrolling='no' src='https://www.strava.com/athletes/2030038/latest-rides/b1e6aa3e2b9e1004af55dfcb76914964ab4da054'></iframe>

      <p>Find my blog posts about <code>running</code> here: <a href="/blog/tag/running">/blog/tag/running</a></p>

      <h2 id="cooking">Cooking</h2>
      <p>Find my blog posts about <code>cooking</code> here: <a href="/blog/tag/cooking">/blog/tag/cooking</a></p>

    </Layout>
  )
}

export default AboutPage
